/* This example requires Tailwind CSS v2.0+ */
import { HomeIcon } from '@heroicons/react/solid';
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import shallow from 'zustand/shallow';
import useLayoutStore from '../stores/layout';
import { classNames } from '../utils';

export default function Breadcrumbs() {
    const location = useLocation();
    const [customBreadcrumbs, setCustomBreadcrumbs] = useLayoutStore(
        (state) => [state.breadcrumbOverride, state.setBreadcrumbOverride],
        shallow,
    );

    useEffect(() => {
        setCustomBreadcrumbs(null);
    }, [location, setCustomBreadcrumbs]);

    const paths = location.pathname
        .split('/')
        .filter((p) => p)
        .map((p, i, arr) => {
            return {
                label: p,
                to: arr.slice(0, i + 1).join('/'),
            };
        });

    const breadcrumbs = customBreadcrumbs || paths;

    return (
        <nav className="bg-white border-b border-gray-200 flex sticky top-16 z-10" aria-label="Breadcrumb">
            <ol className="max-w-screen-xl w-full px-4 flex space-x-4 sm:px-6 lg:px-8">
                <li className="flex">
                    <div className="flex items-center">
                        <Link to="/" className="text-gray-400 hover:text-gray-500">
                            <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                            <span className="sr-only">Home</span>
                        </Link>
                    </div>
                </li>
                {breadcrumbs.map((crumb, i) => (
                    <li key={i} className="flex">
                        <div className="flex items-center">
                            <svg
                                className="flex-shrink-0 w-6 h-full text-gray-200"
                                viewBox="0 0 24 44"
                                preserveAspectRatio="none"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true"
                            >
                                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                            </svg>
                            <Link
                                to={crumb.to}
                                className={classNames(
                                    'ml-4 text-sm font-medium capitalize text-gray-500 hover:text-gray-700',
                                    i !== breadcrumbs.length - 1 ? '' : 'pointer-events-none',
                                )}
                            >
                                {crumb.label}
                            </Link>
                        </div>
                    </li>
                ))}
            </ol>
        </nav>
    );
}
