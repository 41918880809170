import { Navigate, Route, Routes } from 'react-router-dom';
import Forecasts from './Forecasts';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import NewForecast from './NewForecast';
import Products from './Products';
import Customers from './Customers';
import ForecastResult from './ForecastResult';
import Breadcrumbs from '../components/Breadcrumbs';
import ForecastEdit from './ForecastEdit';
import useLayoutStore from '../stores/layout';
import { classNames } from '../utils';
import Analysis from './Analysis';

export default function Layout() {
    const sidebarCollapsed = useLayoutStore((state) => state.sideBarCollapsed);

    return (
        <>
            <div className="h-full">
                <Sidebar />
                <div className={classNames(sidebarCollapsed ? 'md:pl-16' : 'md:pl-64', 'flex flex-col flex-1')}>
                    <Header />

                    <main className="flex-1">
                        <Breadcrumbs />

                        <div className="py-6">
                            <div className="max-w-screen-2xl">
                                <Routes>
                                    <Route path="forecasts/new" element={<NewForecast />} />
                                    <Route path="forecasts/:forecastId" element={<NewForecast />} />
                                    <Route path="forecasts/:forecastId/result" element={<ForecastResult />} />
                                    <Route path="forecasts/:forecastId/edit" element={<ForecastEdit />} />
                                    <Route path="forecasts" element={<Forecasts />} />
                                    <Route path="products" element={<Products />} />
                                    <Route path="customers" element={<Customers />} />
                                    <Route path="analysis" element={<Analysis />} />
                                    <Route path="/*" element={<Navigate replace to="forecasts" />} />
                                </Routes>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
}
