import { useState } from 'react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { Combobox } from '@headlessui/react';
import { classNames } from '../utils';

interface AutoCompleteProps<T> {
    list: T[];
    current: T[];
    onChange: (newValues: T) => void;
    label?: string;
    itemLabelAccessor?: (item: T) => string;
    highlightedItemChecker?: (item: T) => boolean;
    itemHighlighter?: JSX.Element;
}

export default function AutoComplete<T>({
    list,
    onChange,
    current,
    label = '',
    itemLabelAccessor = (item) => (typeof item === 'string' ? item : ''),
    highlightedItemChecker,
    itemHighlighter,
}: AutoCompleteProps<T>) {
    const [query, setQuery] = useState('');

    const filteredList =
        query === ''
            ? list
            : list.filter((item) => {
                  return itemLabelAccessor(item).toLowerCase().includes(query.toLowerCase());
              });

    return (
        <Combobox
            as="div"
            value={itemLabelAccessor(current[0])}
            onChange={(value: any) => {
                onChange(value);
            }}
        >
            <Combobox.Label className="block text-sm font-medium text-gray-700">{label}</Combobox.Label>
            <div className="relative mt-1">
                <Combobox.Input
                    className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                    onChange={(event) => setQuery(event.target.value)}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </Combobox.Button>

                {filteredList.length > 0 && (
                    <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {filteredList.map((item, idx) => (
                            <Combobox.Option
                                key={idx}
                                value={item}
                                className={({ active }) =>
                                    classNames(
                                        'relative cursor-default select-none py-2 pl-6 pr-9',
                                        active ? 'bg-ru-blue text-white' : 'text-gray-900',
                                    )
                                }
                            >
                                {({ active, selected }) => (
                                    <div>
                                        {highlightedItemChecker && highlightedItemChecker(item) && (
                                            <span className={classNames('absolute inset-y-0 left-0 flex items-center')}>
                                                {itemHighlighter}
                                            </span>
                                        )}
                                        <span
                                            className={classNames(
                                                'block truncate',
                                                current.some(
                                                    (curr) => itemLabelAccessor(curr) === itemLabelAccessor(item),
                                                )
                                                    ? 'font-semibold'
                                                    : '',
                                            )}
                                        >
                                            {itemLabelAccessor(item)}
                                        </span>

                                        {current.some(
                                            (curr) => itemLabelAccessor(curr) === itemLabelAccessor(item),
                                        ) && (
                                            <span
                                                className={classNames(
                                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                                    active ? 'text-white' : 'text-ru-blue',
                                                )}
                                            >
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                        )}
                                    </div>
                                )}
                            </Combobox.Option>
                        ))}
                    </Combobox.Options>
                )}
            </div>
        </Combobox>
    );
}
